import { connect } from 'react-redux';

import { IconWithBadge } from 'mastodon/components/icon_with_badge';

const mapStateToProps = state => ({
  count: state.getIn(['conversations', 'unread']),
  id: 'at',
});

export default connect(mapStateToProps)(IconWithBadge);
